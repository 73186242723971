.btn-letters {
    width: 500px;
}


@media (max-width: 768px) {
    .btn-letters {
        width: 100%;
    }
}

.btn-letters button {
    border: none;
    font-size: 1.4rem;
    cursor: pointer;
    width: 30px;
    margin: 8px 2px;
    outline: none;
    background: #00bcd4;
    color: #fff;
    box-shadow: 0 6px #0097a7;
    border-radius: 5px;
    padding-top: 4px;
}

.btn-letters button:hover {
    background: #0097a7;
    box-shadow: 0 6px #007c91;
}

.btn-letters button:disabled {
    background: #747474;
    color: #fff;
    box-shadow: 0 6px #757575;
    cursor: not-allowed;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    animation: bounce 1s;
}